import React, { Component, Fragment } from "react";
import Link from "next/link";
import styled from "styled-components";

import MatchService from "../../services/matches";

import { UserContext } from "../../contexts/UserContext";

import UserContextFormatter from "../../util/user-context-formatter";

import AdManagerUnit from "../Shared/AdManagerUnit";
import Title from "../Shared/Title";
import Button, { buttonThemes, buttonSizes } from "../Shared/Button";
import Switch from "../Shared/Switch";

import MatchList from "./MatchList";

import GenericLiveOdds from "../LiveOdds";
import providers from "../LiveOdds/providers";

export default class HomeRight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      favoriteMatches: [],
      favoriteResults: [],
      matchesFilter: false,
      resultsFilter: false,
    };
  }

  getFavoriteMatches = (isFinished = false) => {
    const userContext = this.context;

    const teamIds = UserContextFormatter.getTeamIdsFromFavorites(
      userContext.favorites.teams
    );

    const tournamentIds = UserContextFormatter.getTournamentIdsFromFavorites(
      userContext.favorites.tournaments
    );

    const matchStatus = isFinished ? 1 : 0;

    MatchService.getMatches(1, 20, matchStatus, 0, teamIds, tournamentIds).then(
      (response) => {
        if (isFinished) {
          this.setState({
            favoriteResults: response.data.data.list.slice(0, 8),
          });
          return;
        }

        this.setState({
          favoriteMatches: response.data.data.list.slice(0, 8),
        });
      }
    );
  };

  render() {
    const { favoriteMatches, favoriteResults, matchesFilter, resultsFilter } =
      this.state;

    const { isLoggedIn } = this.context;

    return (
      <Fragment>
        {/* <GenericLiveOdds provider={providers.betboom} limit={3} /> */}
        <AdManagerUnit name="HOMEPAGE_LARGE_RECTANGLE_RIGHT" />

        <TitleContainer>
          <Title text="Próximas Partidas" />
          {isLoggedIn && (
            <Switch
              small
              label="Favoritos"
              onChange={() => {
                this.setState(
                  {
                    matchesFilter: !matchesFilter,
                  },
                  () => {
                    this.getFavoriteMatches();
                  }
                );
              }}
            />
          )}
        </TitleContainer>
        <MatchList
          data={matchesFilter ? favoriteMatches : this.props.matches}
          small
          hideDates
        />
        <Link prefetch={false} href="/proximas-partidas" passHref>
          <Button
            theme={buttonThemes.default.ACCENT}
            size={buttonSizes.SMALL}
            text="Mais Partidas"
          />
        </Link>
        <br />
        <AdManagerUnit name="HOMEPAGE_LARGE_RECTANGLE_RIGHT_BOTTOM" />
        <TitleContainer>
          <Title text="Resultados" margin />
          {isLoggedIn && (
            <Switch
              small
              label="Favoritos"
              onChange={() => {
                this.setState(
                  {
                    resultsFilter: !resultsFilter,
                  },
                  () => {
                    this.getFavoriteMatches(true);
                  }
                );
              }}
            />
          )}
        </TitleContainer>
        <MatchList
          data={resultsFilter ? favoriteResults : this.props.results}
          small
          hideDates
          reverse
        />
        <Link prefetch={false} href="/resultados" passHref>
          <Button
            theme={buttonThemes.default.ACCENT}
            size={buttonSizes.SMALL}
            text="Mais Resultados"
          />
        </Link>
      </Fragment>
    );
  }
}

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

HomeRight.contextType = UserContext;
