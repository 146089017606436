import React from "react";
import apple from "./apple";
import bars from "./bars";
import caretRight from "././caret-right";
import caretUp from "./caret-up";
import caretDown from "./caret-down";
import check from "./check";
import circle from "./circle";
import close from "./close";
import clock from "./clock";
import cog from "./cog";
import facebook from "./facebook";
import facebookF from "./facebook-f";
import instagram from "./instagram";
import googlePodcasts from "./google-podcasts";
import playCircle from "./play-circle";
import plus from "./plus";
import spinner from "./spinner";
import star from "./star";
import newspaper from "./newspaper";
import twitter from "./twitter";
import telegram from "./telegram";
import telegramT from "./telegram-t";
import whatsapp from "./whatsapp";
import youtube from "./youtube";
import twitch from "./twitch";
import steam from "./steam";
import search from "./search";
import spotify from "./spotify";
import user from "./user";
import question from "./question";
import calendar from "./calendar";
import circleArrowUp from "./circle-arrow-up";
import circleArrowDown from "./circle-arrow-down";
import hamburguerMenu from "./hamburguer-menu";
import list from "./list";
import trophy from "./trophy";
import tiktok from "./tiktok";
import gamersclub from "./gamersclub";
import draft5 from "./draft5";
import redirect from "./redirect";
import ticket from "./ticket";
import ticketButton from "./ticket-button";
import television from "./television";
import televisionoff from "./television-off";
import users from "./users";
import closeModal from "./close-modal";
import share from "./share";
import stats from "./stats";
import blank from "./blank";
import minus from "./minus";
import vzone from "./vzone";
import warning from "./warning";
import threads from "./threads";
import bluesky from "./bluesky";
import twitterx from "./twitterx";
import sales from "./sales";
import medal from "./medal";

import styled, { keyframes, css } from "styled-components";

const COMPONENT_NAME = {
  apple,
  bars,
  "caret-right": caretRight,
  "caret-up": caretUp,
  "caret-down": caretDown,
  check,
  close,
  "close-modal": closeModal,
  clock,
  circle,
  cog,
  facebook,
  facebookF,
  instagram,
  "google-podcasts": googlePodcasts,
  "play-circle": playCircle,
  plus,
  spinner,
  star,
  steam,
  spotify,
  search,
  twitter,
  newspaper,
  telegram,
  telegramT,
  whatsapp,
  youtube,
  user,
  twitch,
  question,
  calendar,
  list,
  trophy,
  tiktok,
  gamersclub,
  draft5,
  "circle-arrow-up": circleArrowUp,
  "circle-arrow-down": circleArrowDown,
  "hamburguer-menu": hamburguerMenu,
  redirect,
  ticket,
  users,
  ticketButton,
  television,
  televisionoff,
  share,
  stats,
  blank,
  minus,
  vzone,
  warning,
  threads,
  bluesky,
  twitterx,
  sales,
  medal,
};

const Icon = (props) => {
  const Component = COMPONENT_NAME[props.name];

  if (!Component) {
    console.log(`SVG NOT FOUND: ${props.name}.`);

    return "";
  }

  const StyledIcon = styled(Component)`
    transform-origin: center;
    ${(props) => !!props.spin && animationMixin};
  `;

  return (
    <StyledIcon
      // style={{ border: "1px solid red" }}
      viewBox="0 0 24 24"
      width={props.size}
      height={props.size}
      {...props}
    />
  );
};

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const animationMixin = css`
  animation: ${spinAnimation} 0.75s linear infinite;
`;

export default Icon;
