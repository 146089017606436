import React, { useContext, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";

import { API_BASE_URL, S3_BASE_URL } from "../../../config/constants";

import { UserContext } from "../../../contexts/UserContext";

import { useClickOutsideAlert } from "../../../hooks/useClickOutsideAlert";
import { useDeviceTypeDetect } from "../../../hooks/useDeviceTypeDetect";

import { buttonThemes, buttonSizes } from "../Button";
import Icon from "../Icon";
import { BannerCTA } from "../../Major23/BannerCTA";

import {
  Container,
  Wrapper,
  Menus,
  Products,
  LogoLink,
  Logo,
  ProductsMenu,
  ProductsMenuButton,
  ProductsMenuDropdown,
  Product,
  HamburguerMenu,
  HamburguerMenuButton,
  HamburguerMenuDropdown,
  CoreRoutes,
  MainPages,
  SecondaryPages,
  AuthOptions,
  AuthButton,
  HamburguerMenuSocialMedias,
  HighlightedRoutes,
  Pages,
  RightSideMenu,
  RightSideMenuSocialMedias,
  SearchForm,
  SearchBar,
  NavbarPlaceholder,
  StrongCFG,
} from "./styles";
import { colors } from "../../../config/styles";
import styled from "styled-components";

const DRAFT5_SOCIAL_MEDIAS = [
  {
    id: "facebook",
    icon: "facebook",
    url: "https://facebook.com/DRAFT5gg/",
  },
  {
    id: "instagram",
    icon: "instagram",
    url: "https://instagram.com/draft5gg/",
  },
  {
    id: "twitter",
    icon: "twitterx",
    url: "https://x.com/draft5gg",
  },
  {
    id: "youtube",
    icon: "youtube",
    url: "https://youtube.com/gamersclubmediatv?sub_confirmation=1",
  },
  {
    id: "tiktok",
    icon: "tiktok",
    url: "https://tiktok.com/@gamersclubmedia",
  },
  {
    id: "threads",
    icon: "threads",
    url: "https://threads.net/@draft5gg",
  },
  {
    id: "bluesky",
    icon: "bluesky",
    url: "https://bsky.app/profile/draft5.gg",
  },
];

export function Header() {
  const router = useRouter();
  const user = useContext(UserContext);
  const { isMobile } = useDeviceTypeDetect();

  const [isProductMenuDropdownOpen, setIsProductMenuDropdownOpen] =
    useState(false);
  const productMenuDropdownRef = useRef(null);
  useClickOutsideAlert({
    ref: productMenuDropdownRef,
    fn: () => {
      setIsProductMenuDropdownOpen(false);
    },
  });

  const [isHamburguerMenuDropdownOpen, setIsHamburguerMenuDropdownOpen] =
    useState(false);
  const hamburguerMenuDropdownRef = useRef(null);
  useClickOutsideAlert({
    ref: hamburguerMenuDropdownRef,
    fn: () => {
      setIsHamburguerMenuDropdownOpen(false);
    },
  });

  const [search, setSearch] = useState("");

  useEffect(() => {
    function closeDropdowns() {
      if (isProductMenuDropdownOpen) setIsProductMenuDropdownOpen(false);
      if (isHamburguerMenuDropdownOpen) setIsHamburguerMenuDropdownOpen(false);
    }

    window.addEventListener("click", closeDropdowns);

    return () => {
      window.removeEventListener("click", closeDropdowns);
    };
  }, [isProductMenuDropdownOpen, isHamburguerMenuDropdownOpen]);

  function toggleProductMenuDropdown() {
    setIsProductMenuDropdownOpen(!isProductMenuDropdownOpen);
  }

  function toggleHamburguerMenuDropdown() {
    setIsHamburguerMenuDropdownOpen(!isHamburguerMenuDropdownOpen);
  }

  function handleSearchSubmit(e) {
    e.preventDefault();
    const searchUrl = !!search ? `/busca?q=${search}` : `/busca`;
    router.push(searchUrl);
  }

  function handleSearchInputChange(e) {
    setSearch(e.target.value);
  }

  function handleEnterKeyPressOnSearch(e) {
    if (e.key === "Enter") handleSearchSubmit(e);
  }

  function isStafAccount() {
    if (
      user.userData &&
      `${user.userData.email}`.endsWith("@gamersclub.com.br")
    ) {
      console.log("Perfil de staff");
      return true;
    }
    return false;
  }

  return (
    <>
      <NavbarPlaceholder>
        <global-navbar
          product="CSGO"
          brand="D5"
          fixed="true"
          withNews="true"
          defaultLanguage="pt"
          style={{
            top: 0,
            position: "absolute",
          }}
          isSimpleBar={isMobile}
        />
      </NavbarPlaceholder>
      {/* Caso tenha o banner BannerCTA deve ser passado em platform draft5 caso contrário apenas draft5-hub */}
      {/* {isStafAccount() && (
        <div style={{ position: "absolute", zIndex: 11 }}>
          <chat-and-friends platform={"draft5"}></chat-and-friends>
        </div>
      )} */}
      <Container>
        <Wrapper>
          <Menus>
            <Products>
              <Link prefetch={false} passHref href="/">
                <LogoLink>
                  <Logo
                    src={`${S3_BASE_URL}/assets/logo.png`}
                    alt="DRAFT5"
                    height={58}
                    width={150}
                  />
                </LogoLink>
              </Link>

              <ProductsMenu ref={productMenuDropdownRef}>
                <ProductsMenuButton onClick={toggleProductMenuDropdown}>
                  <Icon
                    name="circle-arrow-down"
                    viewBox={`-0.5 -1.25 ${16} ${16}`}
                  />
                </ProductsMenuButton>

                {isProductMenuDropdownOpen && (
                  <ProductsMenuDropdown>
                    <Product href="https://valorantzone.gg/" target="_blank">
                      <img
                        src={`${S3_BASE_URL}/assets/valorantzone-logo.png`}
                        alt="VALORANTZONE"
                      />
                      <div>
                        <strong>Valorant Zone</strong>
                        <span>A central de VALORANT do Brasil</span>
                      </div>
                    </Product>

                    <Product href="https://gamersclub.com.br/" target="_blank">
                      <img
                        src={`${S3_BASE_URL}/assets/gamers-club-gc.png`}
                        alt="Gamers Club"
                      />
                      <div>
                        <strong>Gamers Club</strong>
                        <span>A maior plataforma da américa latina</span>
                      </div>
                    </Product>
                  </ProductsMenuDropdown>
                )}
              </ProductsMenu>
            </Products>

            <HamburguerMenu ref={hamburguerMenuDropdownRef}>
              <HamburguerMenuButton onClick={toggleHamburguerMenuDropdown}>
                <Icon
                  name="hamburguer-menu"
                  viewBox={`-${32 / 4.5} -${32 / 3.5} ${32} ${32}`}
                  fill="white"
                />
                <strong>Menu</strong>
              </HamburguerMenuButton>

              {isHamburguerMenuDropdownOpen && (
                <HamburguerMenuDropdown>
                  <CoreRoutes>
                    <MainPages>
                      <Link prefetch={false} passHref href="/">
                        Home
                      </Link>
                      <Link prefetch={false} passHref href="http://melhoresdoano.draft5.gg/" target="_blank">
                        <a target="_blank" rel="noopener noreferrer">Melhores do ano🏅</a>
                      </Link>
                      <Link prefetch={false} passHref href="/cs-atualizacoes">
                        Atualizações CS
                      </Link>
                      <Link prefetch={false} passHref href="/proximas-partidas">
                        Próximas Partidas
                      </Link>
                      <Link prefetch={false} passHref href="/resultados">
                        Resultados
                      </Link>
                      <Link prefetch={false} passHref href="/campeonatos">
                        Campeonatos
                      </Link>
                      <Link prefetch={false} passHref href="/ofertas">
                        Ofertas🔥
                      </Link>
                    </MainPages>

                    <hr />

                    <SecondaryPages>
                      <Link prefetch={false} passHref href="/config-dos-pro">
                        Config dos Pro
                      </Link>
                      {/* <Link prefetch={false} passHref href="/cs2">
                        Counter-strike 2
                      </Link> */}
                      {/* <Link prefetch={false} passHref href="/ranking">
                        Ranking da Valve
                      </Link> */}
                      <Link prefetch={false} passHref href="/podcasts">
                        Podcasts
                      </Link>
                      <Link prefetch={false} passHref href="/casas-de-apostas">
                        Apostas
                      </Link>
                      <Link prefetch={false} passHref href="/sobre-nos">
                        Sobre nós
                      </Link>
                    </SecondaryPages>

                    <AuthOptions>
                      {user.isLoggedIn ? (
                        <Link
                          prefetch={false}
                          passHref
                          href="/perfil/favoritos"
                        >
                          <AuthButton
                            theme={buttonThemes.default.ACCENT}
                            size={buttonSizes.TINY}
                            text={user.userData.displayName}
                            icon="cog"
                          />
                        </Link>
                      ) : (
                        <>
                          <AuthButton
                            href={`${API_BASE_URL}/auth/gcid/login`}
                            theme={buttonThemes.default.WHITE}
                            size={buttonSizes.TINY}
                            icon="steam"
                            text="Login"
                            noBlank
                          />
                          <Link
                            prefetch={false}
                            passHref
                            href={`${API_BASE_URL}/auth/gcid/login`}
                          >
                            <AuthButton
                              theme={buttonThemes.default.ACCENT}
                              size={buttonSizes.TINY}
                              text="Cadastre-se"
                              icon="user"
                              noBlank
                            />
                          </Link>
                        </>
                      )}
                    </AuthOptions>

                    <HamburguerMenuSocialMedias>
                      {DRAFT5_SOCIAL_MEDIAS.map((socialMedia) => (
                        <a
                          key={`HamburguerMenu-${socialMedia.id}`}
                          href={socialMedia.url}
                          target="_blank"
                        >
                          <Icon name={socialMedia.icon} />
                        </a>
                      ))}
                    </HamburguerMenuSocialMedias>
                  </CoreRoutes>
                  <HighlightedRoutes></HighlightedRoutes>
                </HamburguerMenuDropdown>
              )}
            </HamburguerMenu>
          </Menus>

          <Pages>
            <li>
              <Link prefetch={false} passHref href="/proximas-partidas">
                <a>
                  <Icon
                    name="calendar"
                    size={32}
                    viewBox={`-${32 / 3.5} -${32 / 4.5} ${32} ${32}`}
                    fill="#d5d5d5"
                  />
                  <strong>Próximas partidas</strong>
                </a>
              </Link>
            </li>
            <li>
              <Link prefetch={false} passHref href="/resultados">
                <a>
                  <Icon
                    name="list"
                    size={32}
                    viewBox={`-${1000 / 3.5} -${1000 / 4.5} ${1000} ${1000}`}
                    fill="#d5d5d5"
                  />
                  <strong>Resultados</strong>
                </a>
              </Link>
            </li>
            <li>
              <Link prefetch={false} passHref href="/campeonatos">
                <a>
                  <Icon
                    name="trophy"
                    size={32}
                    viewBox={`-${32 / 3.5} -${32 / 4} ${32} ${32}`}
                    fill="#d5d5d5"
                  />
                  <strong>Campeonatos</strong>
                </a>
              </Link>
            </li>
            <li>
              {/* <Link prefetch={false} passHref href="/ofertas">
                <a>
                  <Icon
                    name="sales"
                    size={18}
                    style={{ marginRight: "6px" }}
                    fill={`${colors.MJH_YELLOW}`}
                  />
                  <StrongCFG>Ofertas</StrongCFG>
                </a>
              </Link> */}
              <Link prefetch={false} passHref href="http://melhoresdoano.draft5.gg/">
                <a target="_blank">
                  <Icon
                    name="medal"
                    size={18}
                    style={{ marginRight: "6px" }}
                    viewBox={`0 0 296.193 296.193`}
                    fill={`${colors.WP_LIGHT_GREEN_CYAN}`}
                  />
                  <StrongCFG>Melhores do Ano</StrongCFG>
                </a>
              </Link>
            </li>
            {/* <li>
              <Link prefetch={false} passHref href="/casas-de-apostas">
                <a>
                  <Icon
                    name="ticket"
                    size={32}
                    viewBox={`-${32 / 3.5} -${32 / 4} ${32} ${32}`}
                    fill="#d5d5d5"
                  />
                  <strong>Apostas</strong>
                </a>
              </Link>
            </li> */}
          </Pages>

          <RightSideMenu>
            <RightSideMenuSocialMedias>
              {DRAFT5_SOCIAL_MEDIAS.map((socialMedia) => (
                <a
                  key={`RightSideMenu-${socialMedia.id}`}
                  href={socialMedia.url}
                  target="_blank"
                >
                  <Icon name={socialMedia.icon} size={14} />
                </a>
              ))}
            </RightSideMenuSocialMedias>

            <SearchForm onSubmit={handleSearchSubmit}>
              <SearchBar>
                <input
                  type="text"
                  placeholder="Buscar"
                  value={search}
                  onChange={handleSearchInputChange}
                  onKeyPress={handleEnterKeyPressOnSearch}
                />

                <button type="submit">
                  <Icon name="search" size={24} />
                </button>
              </SearchBar>
            </SearchForm>
          </RightSideMenu>
        </Wrapper>
      </Container>
    </>
  );
}
